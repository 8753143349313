<template>
  <component :is="appropriateComponent" />
</template>

<script>
import SharedPageMixin from "@/mixins/SharedPageMixin";

export default {
  components: {
    AdminComponent: () => import("@/pages/admin/inspect/InspectWrite"),
    UserComponent: () => import("@/pages/user/inspect/InspectWrite"),
  },
  mixins: [SharedPageMixin],
};
</script>
